@import 'antd/dist/reset.css';

.ant-table-row {
  cursor: pointer
}

@media print {
  .hidden-print {
    display: none !important;
  }

  div.ant-card {
    break-inside: avoid;
  }

  .ant-layout-header {
    -webkit-print-color-adjust: exact;
  }

  /* 
    source: https://stackoverflow.com/questions/26011254/chrome-print-preview-different-than-in-devtools
    but doesn't help
    */
  /* div.recharts-responsive-container * {
    transition: none !important;
  } */

}

@page {
  size: landscape;
  margin: 0;
  
  /* margin: 5mm 5mm 5mm 5mm; */
}


div.recharts-responsive-container {
  width: 75% !important;
  /* margin-right: 100px !important; */
}
